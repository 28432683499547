<template>
  <div>
    <b-modal id="create-account-access-key" hide-footer
             title="Create API Key"
             :size="size"
    >
      <b-form @submit.prevent="generateAccessKey">
        <b-form-group>
          <label for="description" class="label-sm">
            {{ accessKeyDescription }}
          </label>
          <b-form-textarea
            v-model="description"
            class="mb-4"
            name="description"
            placeholder="Enter a description..."
            rows="3"
            max-rows="6"
            maxlength="500"
          />
        </b-form-group>
        <b-button
          class="mt-4 submit-btn-width"
          variant="primary"
          type="submit"
          :disabled="submitLoading"
        >
          Submit
        </b-button>
      </b-form>
    </b-modal>
    <b-modal
      id="show-account-access-key"
      hide-footer
      title="View Secret Key"
      :size="size"
    >
      <b-alert
        class="d-flex align-items-center"
        variant="danger"
        show
      >
        <b-icon
          icon="exclamation-triangle-fill"
          variant="warning"
          class="mr-2"
        />
        <p class="mb-0">Important: Please copy and securely store this secret key. For your security, it will not be shown again.</p>
      </b-alert>
      <b-form @submit.prevent="closeShowCreateAccountAccessKey">
        <b-form-group>
          <b-input-group class="mb-3">
            <b-input-group-prepend class="input-icon-container pl-4 pr-4">
              Access Key
            </b-input-group-prepend>

            <b-form-input v-model="accessKey" class="mb-0" type="text" />

            <b-input-group-append>
              <b-button
                class="copy-icon"
                variant="outline-primary"
                size="sm"
                @click="copyToClipboard(accessKey, null, successToast, errorToast)"
              >
                <copy-icon />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-input-group>
            <b-input-group-prepend class="input-icon-container pl-4 pr-4">
              Secret Key
            </b-input-group-prepend>

            <b-form-input v-model="secretKey" class="mb-0" type="text" />

            <b-input-group-append>
              <b-button
                class="copy-icon"
                variant="outline-primary"
                size="sm"
                @click="copyToClipboard(secretKey, null, successToast, errorToast)"
              >
                <copy-icon />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button
          class="mt-3 submit-btn-width"
          variant="primary"
          type="submit"
        >
          Close
        </b-button>
      </b-form>
    </b-modal>
    <b-modal
      id="edit-account-access-key"
      hide-footer
      title="Edit API Key"
      :size="size"
    >
      <b-form @submit.prevent="editAccessKey">
        <b-form-group>
          <b-form-group>
            <label for="description" class="label-sm">
              {{ accessKeyDescription }}
            </label>
            <b-form-textarea
              v-model="description"
              class="mb-3"
              name="description"
              placeholder="Enter a description..."
              rows="3"
              max-rows="6"
              maxlength="500"
            />
            <b-checkbox v-model="enabled">Enabled</b-checkbox>
          </b-form-group>
        </b-form-group>
        <b-button
          class="submit-btn-width"
          variant="primary"
          type="submit"
          :disabled="submitLoading"
        >
          Save
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'AccountAccessKeyModal',
  components: {
    CopyIcon: () => import('@images/ui/copy-icon.svg'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  props: {
    bus: {
      type: Vue,
      required: true,
    },
  },
  data() {
    return {
      description: '',
      size: 'xl',
      environment: 'production',
      accessKey: '',
      secretKey: '',
      enabled: true,
      currentKeyId: '',
      accessKeyDescription: 'Give your access key a description to help you remember (optional)',
      submitLoading: false,
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
  },
  mounted() {
    this.bus.$on('show-create-account-access-key', () => {
      this.environment = 'production'
      this.description = ''
      this.$bvModal.show('create-account-access-key')
    })
    this.bus.$on('show-edit-account-access-key', (key) => {
      this.description = key.description
      this.environment = key.environment
      this.enabled = !key.deletedAt
      this.currentKeyId = key.id
      this.$bvModal.show('edit-account-access-key')
    })
  },
  methods: {
    ...mapActions('accountAccessKey', ['create', 'update']),
    async generateAccessKey() {
      if (this.submitLoading) {
        return
      }
      try {
        this.submitLoading = true
        const response = await this.create({
          record: {
            accountId: this.actualAccountID,
            environment: this.environment,
            description: this.description,
          },
        })
        this.accessKey = response.accessKey
        this.secretKey = response.secretKey
        this.$bvModal.hide('create-account-access-key')
        this.$bvModal.show('show-account-access-key')
      } catch (error) {
        console.error(error)
        this.errorToast("Error", "Failed to create api key")
      } finally {
        this.submitLoading = false
      }
    },
    async editAccessKey() {
      if (this.submitLoading) {
        return
      }
      try {
        this.submitLoading = true
        await this.update({
          record: {
            id: this.currentKeyId,
            accountId: this.actualAccountID,
            environment: this.environment,
            description: this.description,
            deleted_at: !this.enabled ? new Date().toISOString() : null,
          },
        })
        this.$bvModal.hide('edit-account-access-key')
      } catch (error) {
        console.error(error)
        this.errorToast("Error", "Failed to update api key")
      } finally {
        this.submitLoading = false
      }
    },
    closeShowCreateAccountAccessKey() {
      this.$bvModal.hide('show-account-access-key')
    },
  },
}
</script >
<style scoped >
.input-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc; /* Outline border */
  border-radius: 0.25rem; /* Match the input's border radius */
}

.copy-icon {
  height: 100%;
  border-radius: 0.25rem;
}
</style >
