var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "create-account-access-key",
            "hide-footer": "",
            title: "Create API Key",
            size: _vm.size,
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.generateAccessKey.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "label",
                    { staticClass: "label-sm", attrs: { for: "description" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.accessKeyDescription) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("b-form-textarea", {
                    staticClass: "mb-4",
                    attrs: {
                      name: "description",
                      placeholder: "Enter a description...",
                      rows: "3",
                      "max-rows": "6",
                      maxlength: "500",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4 submit-btn-width",
                  attrs: {
                    variant: "primary",
                    type: "submit",
                    disabled: _vm.submitLoading,
                  },
                },
                [_vm._v("\n        Submit\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "show-account-access-key",
            "hide-footer": "",
            title: "View Secret Key",
            size: _vm.size,
          },
        },
        [
          _c(
            "b-alert",
            {
              staticClass: "d-flex align-items-center",
              attrs: { variant: "danger", show: "" },
            },
            [
              _c("b-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: "exclamation-triangle-fill",
                  variant: "warning",
                },
              }),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "Important: Please copy and securely store this secret key. For your security, it will not be shown again."
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.closeShowCreateAccountAccessKey.apply(
                    null,
                    arguments
                  )
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-input-group-prepend",
                        { staticClass: "input-icon-container pl-4 pr-4" },
                        [_vm._v("\n            Access Key\n          ")]
                      ),
                      _c("b-form-input", {
                        staticClass: "mb-0",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.accessKey,
                          callback: function ($$v) {
                            _vm.accessKey = $$v
                          },
                          expression: "accessKey",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "copy-icon",
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(
                                    _vm.accessKey,
                                    null,
                                    _vm.successToast,
                                    _vm.errorToast
                                  )
                                },
                              },
                            },
                            [_c("copy-icon")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { staticClass: "input-icon-container pl-4 pr-4" },
                        [_vm._v("\n            Secret Key\n          ")]
                      ),
                      _c("b-form-input", {
                        staticClass: "mb-0",
                        attrs: { type: "text" },
                        model: {
                          value: _vm.secretKey,
                          callback: function ($$v) {
                            _vm.secretKey = $$v
                          },
                          expression: "secretKey",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "copy-icon",
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard(
                                    _vm.secretKey,
                                    null,
                                    _vm.successToast,
                                    _vm.errorToast
                                  )
                                },
                              },
                            },
                            [_c("copy-icon")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-3 submit-btn-width",
                  attrs: { variant: "primary", type: "submit" },
                },
                [_vm._v("\n        Close\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-account-access-key",
            "hide-footer": "",
            title: "Edit API Key",
            size: _vm.size,
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.editAccessKey.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label-sm",
                          attrs: { for: "description" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.accessKeyDescription) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("b-form-textarea", {
                        staticClass: "mb-3",
                        attrs: {
                          name: "description",
                          placeholder: "Enter a description...",
                          rows: "3",
                          "max-rows": "6",
                          maxlength: "500",
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                      _c(
                        "b-checkbox",
                        {
                          model: {
                            value: _vm.enabled,
                            callback: function ($$v) {
                              _vm.enabled = $$v
                            },
                            expression: "enabled",
                          },
                        },
                        [_vm._v("Enabled")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "submit-btn-width",
                  attrs: {
                    variant: "primary",
                    type: "submit",
                    disabled: _vm.submitLoading,
                  },
                },
                [_vm._v("\n        Save\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }